
import { defineComponent, ref } from "vue";
import PageHeader from "@/components/PageHeader.vue";
import PageContent from "@/components/PageContent.vue";
import ProjectItem from "@/components/ProjectItem.vue";

export default defineComponent({
  name: "Projects",
  components: { PageHeader, PageContent, ProjectItem },
  setup() {
    const projects = ref([
      {
        name: "vue-izitoast",
        npmCreateDate: "2018-01-28"
      },
      {
        name: "vue-cbsc",
        npmCreateDate: "2018-02-16"
      }
    ]);

    return { projects };
  }
});
