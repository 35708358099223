
import { defineComponent, ref, reactive, computed, onBeforeMount } from "vue";
import { format } from "date-fns";
import axios from "axios";

const GITHUB_ENDPOINT = "https://api.github.com/repos/arthurvasconcelos/";

export default defineComponent({
  name: "ProjectItem",
  props: {
    repo: {
      type: String,
      required: true
    },
    createDate: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const name = ref("");
    const description = ref("");
    const watchers = ref(0);
    const stars = ref(0);
    const forks = ref(0);
    const issues = ref(0);
    const version = ref(0);
    const downloads = ref(0);
    const flags = reactive({
      gh: false,
      downloads: false,
      version: false
    });
    const isLoaded = computed(
      () => flags.gh && flags.downloads && flags.version
    );

    onBeforeMount(() => {
      axios.get(`${GITHUB_ENDPOINT}${props.repo}`).then(response => {
        const data = response.data;
        name.value = data.name;
        description.value = data.description;
        watchers.value = data.subscribers_count;
        stars.value = data.stargazers_count;
        forks.value = data.forks_count;
        issues.value = data.open_issues_count;
        flags.gh = true;
      });

      axios
        .get(
          `https://api.npmjs.org/downloads/point/${props.createDate}:${format(
            new Date(),
            "yyyy-MM-dd"
          )}/${props.repo}`
        )
        .then(response => {
          downloads.value = response.data.downloads;
          flags.downloads = true;
        });

      axios
        .get(
          `https://raw.githubusercontent.com/arthurvasconcelos/${props.repo}/master/package.json`
        )
        .then(response => {
          version.value = response.data.version;
          flags.version = true;
        });
    });

    return {
      name,
      description,
      watchers,
      stars,
      forks,
      issues,
      version,
      downloads,
      flags,
      isLoaded
    };
  }
});
